  
import 'whatwg-fetch'
import config from 'react-global-configuration';
import cookie from 'react-cookies'
import Action from '../../action/Action';

const ProjectAction = {


  getList(token, offset, limit,  driver_ids, item, simple,  callback, errorHandler){

    const data = new FormData() 
    if(limit){
      data.append('limit', limit)
    }
    if(offset){
      data.append('offset', offset)
    }

    if(item && item.ids )
      data.append('ids', item.ids)
    if(item && item.id )
      data.append('ids', item.ids)
    if(item && item.status && item.status !== "all")
      data.append('status', item.status)
    if(item && item.name && item.name !== "")
      data.append('name', item.name)
    if(item && item.client_id && item.client_id !== "")
      data.append('client_id', item.client_id)
    if(item && item.without_brank_driver){
      data.append('without_brank_driver', true)
    }
    if(driver_ids)
      data.append('driver_ids', driver_ids.join(","))
    data.append('simple', simple)
    const queryString = new URLSearchParams(data).toString()
    const url = config.get("API_PATH") + config.get("API_PROJECT") + "?"  + queryString

    Action.fetchAuthorizedJson( url , token, "GET", {}, null,  callback, errorHandler)
  },
  

  getDetail(token, id,  callback, errorHandler){
    Action.fetchJson( config.get("API_PATH") + config.get("API_PROJECT")  + id  ,  "GET", {}, null,  callback, errorHandler)
  },

  create( token, item,  callback, errorHandler){
    const method = item.id ? "PATCH" : "POST"
    const url = item.id ? config.get("API_PATH") + config.get("API_PROJECT") + item.id  : config.get("API_PATH") + config.get("API_PROJECT")
    Action.fetchAuthorizedJson(url , token, method,  {'Content-Type': 'application/json' }, JSON.stringify(item),  callback, errorHandler)
  },

  delete( token, id,  callback, errorHandler){
    const url =  config.get("API_PATH") + config.get("API_PROJECT") + id 
    Action.fetchAuthorizedDelete(url , token, {},  callback, errorHandler)
  },

  saveOnCookie(item){
    console.log(item)
    cookie.save('project_form',item, { path: '/' })
  },

  loadFromCookie( callback, errorHandler){
    const project =  cookie.load("project_form")
    if(project) {
      callback({data:project})
    }else{
      console.log("load cookie error")
      errorHandler({"error":"not found"})
    }
  },
  hasCookie(){
    const project_form =  cookie.load("project_form")
    if(project_form) return true 
    return false
  },
  removeCookie(){
    cookie.remove('project_form', { path: '/' })
  }
 
 
}

export default ProjectAction
