
import Report from "../Report"

class ReportCommitionAndDate  extends Report{
  delivery_unit = "持出個数"



  drive_start_at =  undefined
  area=  "エリア"
  load_location =  undefined
  delivery_location =  undefined
  requested_unit = "便数"
  distance =  "km"
  meter_start=  undefined
  meter_end =  undefined
  compleated_unit =  undefined
  nekopos_unit=  undefined
  dm_unit =  undefined
  normal_unit =  "配完数"
  night_unit =  undefined
  load_unit =  undefined
  large_unit =  undefined
  waiting_hour =  undefined
  return_requested_unit = undefined
  return_unit = undefined
  fee = "当日単価"

  getTotalUnitNumber(reports:Array<Report>){
  
    if(!this.project_driver) return 0
 
    const over_point_number = this.project_driver.fee_information.over_point_number
    const unit_array:Array<number> = reports.map(report => report.normal_unit ? Number(report.normal_unit) : 0)
    const total = unit_array.reduce((sum, element) => sum + element, 0);
    return Number(total - Number(over_point_number))
  }

}

export default ReportCommitionAndDate

