import React, { useEffect, useState } from 'react'
import { format, subDays, subMonths,differenceInCalendarDays } from 'date-fns' 
import ja from 'date-fns/locale/ja'
import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';
import useProjectState from '../project/useProjectState';
import ReportMyListItem from './ReportMyListItem';


function ReportMyList(props:PropsValue) {

  const PARTNER = "partner"

  const [list_c, setCompanyList] = useState(null)
  //const [list_c_udpated_at, setCompanyListUpdatedAt] = useState(null)
  const [list_th, setThList] = useState(null)
  const {project_list, getProjectList} = useProjectState()
  const today = new Date()


  useEffect(() => {

    if(props.my_project_list){
      const driver_ids = props.my_project_list.map(item => item.driver.id)
      if(driver_ids.length > 0)
        getProjectList(props.token, 1, driver_ids, null, null, true)
      
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.my_project_list])

  useEffect(() => {
    if(props.my_project_list && project_list){
      const start_date = today 
      const end_date = subMonths(today, 1)
      const dif_days = differenceInCalendarDays(start_date,end_date)
      const _list = (new Array(dif_days)).fill(0).map((dummy, index) => {
        return <th  >{format(subDays(start_date,(dif_days - index - 1)),"M/d(E)", {locale: ja})}</th>
      }) 


      setThList(_list.reverse())
      const tyarget_list = props.profile && props.profile.user_type  === PARTNER ? props.my_project_list : props.my_project_list.filter(item => props.inhouse === "1" ? item.driver.company_driver === 1: item.driver.company_driver !== 1)
      setCompanyList(tyarget_list)
      //setCompanyListUpdatedAt(new Date())
    
     
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[project_list, props.my_project_list])


/**useEffect(() => {
 
 
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[list_c_udpated_at])**/


const setCounter = (index, counter) => {
  const _tmp = list_c.concat()

  const pd = _tmp.find((p) => p.id === index);
  pd.counter = counter
 /**const new_list = _tmp.sort(function(first, second){
    if (first.counter < second.counter){
      return 1;
    }else if (first.counter > second.counter){
      return -1;
    }else{
      return 0;
    }
  }).map(item => item)
  setCompanyList(new_list)
  //setCompanyListUpdatedAt(new Date())
  **/
}

const findPrjectName = (list, id) => {
  const project = list.find(item => item.id === id)
  if(project) {
    return project.name
  }
  return null
}

  
const moveToList = (type ) => {
  if(type === "regular" && props.inhouse === "1"){
    window.location.href = "/report/mine?inhouse=0"
  }else if (type === "inhouse"  && props.inhouse !== "1"){
    window.location.href = "/report/mine?inhouse=1"
  }
}


  return(
    <div className="inner">
       
			  <div className="content_wrap">
					  <div className="page_title_wrap">
								<h2 className="page_title" >日報確認チェック</h2>
                <Breadcrumbs pages={[new LinkProps("日報確認チェック","#")]} />
						</div>
            {
              props.profile && props.profile.user_type  === PARTNER ? null :
          
            <div className="tab-box">
                  <div className={ props.inhouse === "1"  ? "tab-item selected" : "tab-item" }  onClick={() => moveToList("inhouse")}>自社</div>
                  <div className={props.inhouse !== "1"  ? "tab-item selected" : "tab-item" } onClick={() => moveToList("regular")} >協力会社</div>
            </div>
              }
            <div className="report_check-container">
      
              <table className="datatable report_check">
                <thead>
                  <tr>
                    <th className="sticky">案件名/ドライバー名</th>
                 
                    {list_th} 
                  </tr>
                </thead>
                <tbody>
                   
                {list_c ?  list_c.map((item) => { 
                          return(
                            <ReportMyListItem  project_name={findPrjectName(project_list, item.project_id)} item={item}  start_date={new Date()} end_date={subMonths(new Date(), 1)} token={props.token} setCounter={setCounter}/>
                          )
                        })
                      :null
                      }
                 
                   
                </tbody>
              </table>
            </div>
				</div>
    
		</div>

  )
}

export default ReportMyList


/**
 *  
 */