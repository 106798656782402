import React, {useEffect, useState} from 'react';
import { addDays,subDays,differenceInDays,format, isSameDay, isValid } from 'date-fns';
import ProjectDriver from '../../components/project/driver/ProjectDriver';
import useReportState from '../../components/report/useReportState';
import Project from '../../components/project/Project';
import "./history.css"


interface ReportHistoryComponentProps {
  project_driver:ProjectDriver,
  project:Project,
  reportLoadedHandler:Function
}

/**
 * Primary UI component for user interaction
 */
export const ReportHistoryComponent = ({
  token,
  project_driver,
  project,
  reportLoadedHandler,
  ...props
}: ReportHistoryComponentProps) => { 

  const [start_date, setStartDate] = useState()
  const [end_date, setEndDate] = useState()
  const [history, setHistory] = useState("Loading..")
  const {report_list, getPublicReportList} = useReportState()


  useEffect(() => {
    console.log(props.target_date)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.target_date])


  useEffect(() => {
    const _start_date = subDays(props.target_date,6)
    const _end_date = addDays(props.target_date,1)
    setStartDate(_start_date)
    setEndDate(_end_date)

    if(isValid(_start_date) && isValid(_end_date)){
      getPublicReportList( project.uid, project_driver.driver_id, format(_start_date, "yyyy-MM-dd"),  format(_end_date , "yyyy-MM-dd"))
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.target_date])

  useEffect(() => {
    if(report_list){
      reportLoadedHandler(report_list)
      const duration = differenceInDays(end_date, start_date)
      const _list = (new Array(duration)).fill(0).map((zero, index) => {
        const target_date = addDays(start_date, index + 1) 
        const report = findReport(target_date)
        return <div className={report? "done" : null}>{format(target_date, "M/d")}<br/> {report? "記入済み" : null}</div>
      }).reverse()
      setHistory(_list)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[report_list])



  const findReport = (target_date) => {
    const reports = report_list.filter(r =>  isSameDay(new Date(r.worked_date), new Date(target_date)))
    if(reports.length === 0) return null
    return reports[0]
  }

  return (
    <div className="report-history">
     {history}
    </div>
  );
};

