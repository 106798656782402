
import MonthlyReportProps from './MonthlyReportProps'
import PaymentItem from '../monthly_payment/PaymentItem';
import Project from '../project/Project';

const props = extendValues => class extends MonthlyReportProps {};
class MonthlyReport extends props({}) {




  reportList(){
    const list = []
    const project_object = new Project(this.project)
    
    //一般
    //スポット
    if(!this.project || this.project.category === 0){
  
      list.push(new PaymentItem({
        date:this.month + "/" + this.date,
        detail:this.content,
        unit_number:this.total_number,
        unit_name:null,
        unit_cost_name:"円",
        unit_price:this.unit_price ? this.unit_price : 0,
        non_tax_price:0,
        driver_name:this.driver.name,
        driver_id:this.driver.id,
        url:this.url,
        cost:this.highway_cost + this.parking_cost + this.other_cost,
        is_unit_price:true,
        is_self:true
      }))
    }
    //Yamato Normal
    else if(this.project.category === 4){
      list.push(new PaymentItem({
        date:this.month + "月",
        detail:this.project.name,
        unit_number:this.total_number_yamato_normal,
        unit_name:"個",
        unit_cost_name:"円/個",
        unit_price:Number(this.project_driver.fee ? this.project_driver.fee.unit_yamato_normal: 0),
        driver_name:this.driver ? this.driver.name  :null ,
        driver_id:this.driver ? this.driver.id : null,
        cost:this.highway_cost + this.parking_cost + this.other_cost,
        url:this.url,
      
      }))
      list.push(new PaymentItem({
        date:"",
        detail:"ネコポス",
        unit_number:this.total_number_yamato_neko,
        unit_name:"個",
        unit_cost_name:"円/個",
        unit_price:Number(this.project_driver.fee ? this.project_driver.fee.unit_yamato_nekopos: 0),
        driver_name:"",
        driver_id:this.driver ? this.driver.id : null,
        cost:this.highway_cost + this.parking_cost + this.other_cost,
        url:this.url
      }))
      list.push(new PaymentItem({
        date:"",
        detail:"DM",
        unit_number:this.total_number_yamato_dm,
        unit_name:"個",
        unit_cost_name:"円/個",
        unit_price:Number(this.project_driver.fee ? this.project_driver.fee.unit_yamato_dm: 0),
        driver_name:"",
        driver_id:this.driver ? this.driver.id : null,
        cost:this.highway_cost + this.parking_cost + this.other_cost,
        url:this.url
      }))
    }
    //Yusei Normal
    //TODO
    //ここをcreateItemに置いておく
    else if(this.project.category === 5){
      list.push(new PaymentItem({
        date:this.month + "月",
        detail:this.project.name,
        unit_number:this.total_number_yusei_normal,
        unit_name:"個",
        unit_cost_name:"円/個",
        unit_price:Number(this.project_driver.fee ? this.project_driver.fee.unit_yusei_normal: 0),
        driver_name:this.driver.name ,
        driver_id:this.driver.id,
        cost:this.highway_cost + this.parking_cost + this.other_cost,
        url:this.url
      }))
      list.push(new PaymentItem({
        date:"",
        detail:"大型",
        unit_number:this.total_number_yusei_big,
        unit_name:"個",
        unit_cost_name:"円/個",
        unit_price:Number(this.project_driver.fee ? this.project_driver.fee.unit_yusei_big: 0),
        driver_name:"",
        driver_id:this.driver.id,
        cost:this.highway_cost + this.parking_cost + this.other_cost,
        url:this.url
      }))
      list.push(new PaymentItem({
        date:"",
        detail:"夜間",
        unit_number:this.total_number_yusei_night,
        unit_name:"個",
        unit_cost_name:"円/個",
        unit_price:Number(this.project_driver.fee ? this.project_driver.fee.unit_yusei_yakan: 0),
        driver_name:"",
        driver_id:this.driver.id,
        cost:this.highway_cost + this.parking_cost + this.other_cost,
        url:this.url
      }))
      list.push(new PaymentItem({
        date:"",
        detail:"集荷",
        unit_number:this.total_number_yusei_collect,
        unit_name:"個",
        unit_cost_name:"円/個",
        unit_price:Number(this.project_driver.fee ? this.project_driver.fee.unit_yusei_collect: 0),
        driver_name:"",
        driver_id:this.driver.id,
        cost:this.highway_cost + this.parking_cost + this.other_cost,
        url:this.url
      }))
    }
    //歩合
    else if(this.project.category === 6 ){
     
      list.push(new PaymentItem({
        date:this.month + "月",
        detail:this.project.name,
        unit_number:this.total_normal_unit,
        unit_name:"個",
        unit_cost_name:"円/個",
        unit_price:Number(this.project_driver.fee ? this.project_driver.fee.unit_price: 0),
        driver_name:this.driver ? this.driver.name : null ,
        driver_id:this.driver ?  this.driver.id : null,
        cost:this.highway_cost + this.parking_cost + this.other_cost,
        url:this.url,
      
      }))

    }

    else if(this.project.category !== 0 ){

      this.units.forEach((unit, index) => {
        list.push(new PaymentItem({
          date:index === 0 ? this.month + "月" : null,
          detail:index === 0 ? this.project.name : null,
          unit_number:unit.total_number,
          unit_name:"日間",
          unit_cost_name:"円/日",
          unit_price:Number(unit.unit_price),
          cost:this.highway_cost + this.parking_cost + this.other_cost,
          driver_name:this.driver ? this.driver.name : null,
          driver_id:this.driver ? this.driver.id : null,
          url:this.url
        }))
      })
      //日当+歩合の場合の歩合
      if(this.project.category === 7 && this.total_number > 0){
     
        list.push(new PaymentItem({
          datet:"",
          detail:"歩合",
          unit_number:Number(this.total_number),
          unit_name: (project_object&& project_object.unit ? project_object.unit : ""),
          unit_cost_name:"円/" + (project_object&& project_object.unit ? project_object.unit : ""),
          unit_price:Number(this.project_driver.fee ? this.project_driver.fee.over_point_unit_price: 0),
          driver_name:"" ,
          driver_id:this.driver ?  this.driver.id : null,
          url:this.url,
        
        }))
  
      }
    }

    //残業
    if(this.over_time_min && this.over_time_min > 0){
      list.push(new PaymentItem({
        date:"",
        detail:"残業代",
        unit_number:this.over_time_min,
        unit_name:"分",
        unit_min:project_object.getOverTimeUnitTime(),
        unit_cost_name:"円/"+ project_object.getOverTimeUnitTime() + "分",
        unit_price:this.project_driver.fee_information && this.project_driver.fee_information.over_time_unit_price ? Number(this.project_driver.fee_information.over_time_unit_price) : 0,
        driver_name:"",
        driver_id:this.driver ? this.driver.id : null,
        url:this.url
      }))
    }

      //超過距離
    if(this.project && this.project.distance_type !==  1 && this.over_distance && this.over_distance > 0){
        list.push(new PaymentItem({
          date:"",
          detail:"超過距離",
          unit_number:this.over_distance,
          unit_name:"km",
          unit_cost_name: "円/km",
          unit_price:this.project_driver.fee_information && this.project_driver.fee_information.over_distance_unit_price ? Number(this.project_driver.fee_information.over_distance_unit_price) : 0,
          driver_name:"",
          driver_id:this.driver ? this.driver.id : null ,
          url:this.url
        }))
      }
    

    return list
  }

}
export default MonthlyReport
