import React, { useEffect, useState } from 'react'

import config from 'react-global-configuration';
import Report from './Report';
import ReportAmazon from './report_category/ReportAmazon';
import ReportYamato from './report_category/ReportYamato';
import ReportYusei from './report_category/ReportYusei';
import ReportSpot from './report_category/ReportSpot';
import ReportCharter from './report_category/ReportCharter';
import Receipt from '../../receipt/Receipt';
import ReportCorporate from './report_category/ReportCorporate';
import ReportSpotPersonal from './report_category/ReportSpotPersonal';
import ReportSpotCharter from './report_category/ReportSpotCharter';
import ReportSpotCorporate from './report_category/ReportSpotCorporate';
import ReportCommition from './report_category/ReportCommition';
import ReportCommitionAndDate from './report_category/ReportCommitionAndDate';
import ProjectDriver from '../project/driver/ProjectDriver';
import { LoadingWindow } from '../../stories/components/loading_window/LoadingWindow';
import { ReportDetail } from '../../stories/components/report_detail/ReportDetail';
import {  setMonth, setDate, setYear,isSameDay, setHours, setMinutes, isAfter, isBefore } from 'date-fns';
import { ReportHistoryComponent } from '../../stories/report_history/ReportHistoryComponent';



function ReportMobDetail(props:PropsValue) {
  const RECEIVED = "recieved"
  const [project, setProject] = useState(null)
  const [project_driver, setProjectDriver] = useState(null)
  const [error_message, setErrorMessage] = useState(null)
  const [item, setItem] = useState(null)
  const [receipts, setReceipts] = useState([])
  const [received, setReceived] = useState(null)
  const [receiptsUpdatedAt, setReceiptsUpdatdAt] = useState(new Date())
  const [receipts_html, setReceiptsHtml] = useState(null)
  const [report, setReport] = useState(new Report())
  const [addBtn, setAddBtn] = useState(null)
  const [loading, setLoading] = useState(null)
  const [report_list, setReportList] = useState([]) 
  useEffect(() => {

    setItem(props.report)
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.report])
  
  
  useEffect(() => {

    setReceipts(props.receipts)
    setReceiptsUpdatdAt(new Date())
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.receipts])



  useEffect(() => {
    
    setReceiptsHtml(
      receipts.map((_receipt, index) => {
        const _index = index
        const select_op = config.get("TATEKAE_TYPE").map(op => {
          return(
            <option value={op.value} >{op.label}</option>
          )
        })
        return(
          <tr  className="colmn" key={"receipt_" + index}>
          <th className="tl">立替金領収書 {(index + 1)}</th>
            <td>
              <select name="description"  className="description" placeholder="領収書項目を記載　例)駐車場代" onChange={(e => receiptChangeHandler(e, _index))} >
              <option value="" selected="">選択してください</option>
                {select_op}
              </select>

              <input type="number" name="price" placeholder="金額 (円）" onChange={(e => receiptChangeHandler(e, _index))}/>
              
              <input type="file" name="file" accept="image/*" onChange={(e => receiptFileChangeHandler(e, _index))} /> 
            </td>
          </tr>
        )
      })
    )
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[receipts, receiptsUpdatedAt])

 

  useEffect(() => {

    setProject(props.project)

   

    var r = null
    //console.log(props.project.category)
    if(props.project && props.project.category){
      
      switch(Number(props.project.category)){
        case 1: r =  new ReportCorporate()
          break
        case 2 : r =  new ReportCharter()
          break;
        case 3: r =  new ReportAmazon()
          break
        case 4: r =  new ReportYamato()
          break
        case 5: r =  new ReportYusei()
          break
        case 6: r =  new ReportCommition()
          break
        case 7: r =  new ReportCommitionAndDate()
          break
    
        default: r =  new Report()
      }
    
      setReport(r)
    }else{
      setReport(new ReportSpot())
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.project])
  
  useEffect(() => { 
    setProjectDriver(new ProjectDriver(props.project_driver))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.project_driver])


  const changeSpotHandler = (e) => {
    const target = e.target
    const new_item = item.set(target.name, target.value)
    setItem(new_item)

    switch(Number(target.value)){
      case 1 : 
        setReport(new ReportSpotCharter())
        break
      case 2 : 
        setReport(new ReportSpotPersonal())
        break
      case 3: 
        setReport(new ReportSpotCorporate())
          break
      default :
        break
    }
  }

  const changeHandler = (e) => {
    const target = e.target
    const new_item = item.set(target.name, target.value)
    setItem(new_item)
  } 

  const changeDateHandler = (e) => {
    
    const value = new Date(e.target.value)
    const start_date = setDate(setMonth(setYear(item.work_start_at, value.getFullYear()),(value.getMonth()) ), value.getDate() ) 
    var new_item = item.set("work_start_at", start_date)

    const end_date = setDate(setMonth(setYear(item.work_end_at, value.getFullYear()),(value.getMonth()) ), value.getDate() ) 
    new_item = new_item.set("work_end_at", end_date)
    setItem(new_item)
  }
  
  
  const btnClickHandler = () => {
  
    if(report_list !== null){
      const target_report = findReport(item.work_start_at)

      var error_drive_start = null
      if(target_report){
        setErrorMessage(<div>この日付のレポートはすでに登録されています</div>)
      }else{
        const errors = report.validate(item)
        if(props.project.working_time_type  === 1 && props.project.overtime_start_at){
          const hour = props.project.overtime_start_at.split(":")[0]
          const min = props.project.overtime_start_at.split(":")[1]
          
          const target_work_time = setMinutes(setHours(item.work_start_at, hour),min)

          if(isBefore(item.work_start_at, target_work_time)){
            error_drive_start = ("業務開始時間は" + props.project.overtime_start_at + "より後の時間を選択してください。")
          }
        }
        if(item.work_start_at && item.drive_start_at){
          var target_driver_start = setHours(item.work_start_at,item.drive_start_at.split(":")[0] )
          target_driver_start = setMinutes(target_driver_start,item.drive_start_at.split(":")[1] )
          if(isAfter(item.work_start_at, target_driver_start)){
            error_drive_start = ("乗車時間が業務開始時間より前になっています。")
          }
        }

        receipts.map(item => {
        if(item.price || item.description || item.file ){
          if(!item.price || !item.description   ){
            errors.push(<div>領収書の項目</div>)
          }
        }
        return true
      })
      if(errors.length >0 ){
        const err = errors.map(item => <div>{item}が未記入です。</div>)
        if(error_drive_start){
          err.push(error_drive_start)
        }
        setErrorMessage(err)
      }else{
        var upload_item = item
        setLoading(<LoadingWindow />)

        if(received){
          const received_receipt = new Receipt(
            {file:received, price:0, description:"受領証"}
          )
          receipts.unshift(received_receipt)
        }
      
        props.updateHandler(upload_item, receipts)
      }
      }
    }
  }

  const receiptChangeHandler =(e, index) =>{
    const target = e.target
    receipts[index] = receipts[index].set(target.name,target.value )
    setReceipts(receipts)
  }


  const addReceipt = () => {
    receipts.push(new Receipt())
    setReceipts(receipts)
    setReceiptsUpdatdAt(new Date())
  }
  
  
  const receiptFileChangeHandler =(e, index) =>{
    if(index !== RECEIVED){
      const _file = e.target.files[0]
      receipts[index] = receipts[index].set("file",_file )
      setReceipts(receipts)

      if(index === (receipts.length -1) && receipts[index].description && receipts[index].price && receipts[index].file){
        setAddBtn(  
          <tr>
            <td colspan="2"><button class="add_receipt" onClick={addReceipt}>他の領収書を追加する</button></td>
          </tr> 
        )
      }
    }else{
      setReceived(e.target.files[0])
    }
    
  }

  const reportLoadedHandler = (_list) => {
    setReportList(_list)
  }

  const findReport = (target_date) => {
    const reports = report_list.filter(r =>  isSameDay(new Date(r.worked_date), (target_date)))
    if(reports.length === 0) return null
    return reports[0]
  }

  return(
    <div className="day-report-sp">
    {
      project_driver? project_driver.is_working() ? 
      <div>
  
       {loading ?  <div id="overlay" className="overlay" ></div> : null}
       {props.token}
       {props.project_driver && props.project && props.project.project_type !== 2 ? <ReportHistoryComponent target_date={item ? item.work_start_at : null}  project_driver = {props.project_driver} project={props.project} reportLoadedHandler={reportLoadedHandler}/> : null}
      <p>日報入力の上、送信してください。必ず送信完了画面が表示されるまで確認してください。</p>
      {item && report ? <ReportDetail driver={props.driver} project={project} project_driver={project_driver} report={report} item={item} changeHandler={changeHandler} changeDateHandler={changeDateHandler} is_editable={true} changeSpotHandler={changeSpotHandler}/> : null}
      <table className="input_table col12 login_tbl receipt-table">
        <tbody>
        {receipts_html} 
        {addBtn}

        <tr  className="colmn" >
          <th className="tl">受領書</th>
            <td>              
              <input type="file" name="file" accept="image/*" onChange={(e => receiptFileChangeHandler(e, RECEIVED))} /> 
            </td>
          </tr>


        </tbody>
      </table>

      {error_message ? <div className="input_wrap error"> <div>{error_message} </div> </div> : null }
      <button className="red" type="button" onClick={btnClickHandler}>この内容で報告する</button>
      {loading}
      </div>
    :<div>このページは現在利用できません。管理者にお問い合わせください。</div>:null}
		</div>
    
  )
}

export default ReportMobDetail

