import { useState } from 'react';
import ProjectAction from './ProjectAction';
import config from 'react-global-configuration';

function useProjectState() {

  const [limit, setLimit] = useState(config.get("SEARCH_LIMIT"))
  const [error, setError] = useState(null)
  const [created, setCreated] = useState(null)
  const [item, setItem] = useState(null)
  const [list, setList] = useState(null)
  const [total_number, setTotalNumber] = useState(null)
  const [list_updated_at, setListUpdatedAt] = useState(null)

  const listLoadeddHandler = (data) => {

    setList(data.data)
    setTotalNumber(data.headers.get("total-number"))
    setListUpdatedAt(new Date())

  }
  const loadedHandler = (data) => {
   
    setItem(data.data)
  }
  const createdHandler = (data) => {
    setItem(data.data)
    setCreated(data.data)
  }

  const errorHandler = (data) => {
    setError(data)
  }
  
  const hasCookie = () => {
    return ProjectAction.hasCookie()
  }

  const removeCookie = () => {
    ProjectAction.removeCookie()
  }

  const saveOnCookie = (item) => {
    var _item = item.set("drivers" ,null)
    _item = _item.set("client" ,null)
    _item = _item.set("user" ,null)
    ProjectAction.saveOnCookie(_item)
  }

  const loadFromCookie = () => {
    ProjectAction.loadFromCookie( loadedHandler, errorHandler)
  }
  
  const getList = (token, page,  driver_ids = null , item, _limit = limit, simple = false) => {
    const offset = (page - 1)  * _limit 
    ProjectAction.getList(token, offset, _limit, driver_ids,item, simple, listLoadeddHandler, errorHandler)
  }

  const create = (token, item) => {
    ProjectAction.create(token, item, createdHandler, errorHandler)
  }

  const getDetail = (token, id) => {
    ProjectAction.getDetail(token, id, loadedHandler, errorHandler)
  }

  const deleteItem = (token, id) => {
    ProjectAction.delete(token, id, createdHandler, errorHandler)
  }
  


  const getEndDateByType = (closing_date) => {
    switch(closing_date){
      case 1 : return 0 
      case 2 : return 25
      case 3 : return 20 
      case 4 : return 15 
      default:
        return 0
    }
  }


  return { project:item, project_list:list, list_updated_at:list_updated_at,project_list_updated_at:list_updated_at, project_error:error, project_deleted:created, project_created:created, project_total_number:total_number, project_search_limit:limit, 
    getProjectList:getList, getProject:getDetail, saveOnCookie:saveOnCookie, loadFromCookie:loadFromCookie, hasCookie:hasCookie, removeCookie:removeCookie, createProject:create, deleteProject:deleteItem,
    getEndDateByType, setProjectSerachLimit:setLimit};
}

export default useProjectState