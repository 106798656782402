import React, { useEffect, useState } from 'react'
import useReportState from './useReportState'
import { format, addDays, isSameDay,differenceInCalendarDays, isBefore ,isAfter} from 'date-fns'

function ReportMyListItem(props:PropsValue) {
 
  //const [is_loading, setIsLoading] = useState(true)
  const [t_list , setTList] = useState(null)


  const {report_list,getReportList } = useReportState()

  useEffect(() => {

    console.log(props.item)

    getReportList(props.token, 1, 1,  props.item.project_id, props.item.driver.id,format(props.end_date,"yyyy-MM-dd"), format(props.start_date,"yyyy-MM-dd"), null, null, true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  useEffect(() => {
    if(report_list){
      const dif_days = differenceInCalendarDays(props.start_date,props.end_date)
      const _list = (new Array(dif_days)).fill(0).map((dummy, index) => {
        return getResult(addDays(props.end_date,index+1))
      })

      const _list_html = _list.map((r, index) => {
        return <td>{getTag(r, addDays(props.end_date,index+1))}</td>
      })
      const _c = _list.filter(r => r === 3).length
      props.setCounter(props.item.id, _c)
  
      setTList(_list_html.reverse())
      //setIsLoading(false)
     
    }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[report_list, props.updated_at])



  const getResult = (date) => {

    const report = report_list.find(r => isSameDay(new Date(r.worked_date), date))
    if(report){
      return 1
    }


    if(isBefore(date, new Date(props.item.start_date))) return 2
    if(props.item.end_date && isAfter(date, new Date(props.item.end_date))) return 2

    var is_target = null
      switch(date.getDay()){
        case 0: 
          is_target = props.item.sunday !== null &&  props.item.sunday !== 0
        break
        case 1: 
          is_target = props.item.monday !== null &&  props.item.monday !== 0
        break
        case 2:  
          is_target = props.item.thuesday !== null &&  props.item.thuesday !== 0
        break
        case 3: 
          is_target = props.item.wednesday !== null &&  props.item.wednesday !== 0
        break
        case 4: 
          is_target = props.item.thursday !== null &&  props.item.thursday !== 0
        break
        case 5: 
          is_target = props.item.friday !== null &&  props.item.friday !== 0
        break
        case 6: 
          is_target = props.item.saturday !== null &&  props.item.saturday !== 0
        break
        default: 
        is_target = false
      }
      
    if(!is_target){
      return 2
    }
    return 3
  }
  const getReportLink = (d) => {

    return "/project/" + props.item.project_uid + "/report/mob/" + props.item.uid + "/new/" + format(d,"yyyy/MM/dd")
  }
  const getTag = (result,d) => {
    
    switch(result){
      case 1: 
        return (<span className="hasreport">日報あり</span>)
      case 2: 
        return (<span>-</span>)
      case 3: 
        return (<span className="noreport"><a href={getReportLink(d)}>日報なし</a></span>)
      default: 
        return null
    }  
  }
  
  return(
    <tr>
      
      <td className="sticky driver-header">
        <div>{props.project_name} </div><div>{props.item.driver.name}({props.item.counter})</div> 
        <div className="tooltip-user">{props.item.manager_name}</div>
      </td>
      {t_list}

    </tr>
  )
}

export default ReportMyListItem

