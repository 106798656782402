import React, { useEffect, useState } from 'react'
import config from 'react-global-configuration';


import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ja from 'date-fns/locale/ja'
import format from 'date-fns/format';
import Utile from '../../utiles/Utile';
import { ClientSearchBox } from '../../stories/components/client_search/ClientSearchBox';
import ReportFormat from '../monthly_report/format/ReportFormat';

function ProjectDetailItem(props:PropsValue) {
  
  const report_format = new ReportFormat()
  const [item, setItem] = useState(null)
  const [format_types, setFormatTypes] = useState(null) 
  const [format_label, setFormatLabel] = useState(null)
  


  useEffect(() => {
    setItem(props.project)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.project])

  useEffect( () => {

    if(item && item.category && props.formats && props.clients){

      const _list = props.formats.map(_item => {

        if(_item.project_ids.split(",").includes("" + item.category)){         
          return(<option value={_item.id}>{_item.name}</option>)
        }else{ return null }
      })
      setFormatTypes(_list)

      const format = report_format.getFormatFromList(props.formats,item.report_type )
      if(format){
        setFormatLabel(format.name)
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[item, props.formats, props.clients])
  

  const changeHandler = (e) => {
    const target = e.target
    const new_item = item.set(target.name, target.value)
    setItem(new_item)
    props.changeHandler(new_item)
  } 

  const changeNameValueHandler = (name, value) => {
    const new_item = item.set(name, value)
    setItem(new_item)
    props.changeHandler(new_item)
  } 

  const changeNumberHandler = (e) => {
    const target = e.target
    const new_item = item.set(target.name, Number(target.value.trim()))
    setItem(new_item)
    props.changeHandler(new_item)
  } 



  const getCleint = ( ) => {
    
    if(!item ) return null
    if(!props.clients) return null 

    const clinet = props.clients.find(c => Number(c.value) === Number(item.client_id))
    if(clinet) return   clinet.label
    if(item.client) return item.client.name
    return "-"
  }

  const onChangeClientSelectHandler = (e) => {
    props.changeClientrHandler(e)
  }


  return(
    <table className="input_table col12 login_tbl">
      <tbody>
      
        <tr>
          <th className="tl">クライアント名 *</th>
          <td>
          { props.is_edit && item && !item.id && props.clients ? 
            <ClientSearchBox changeHandler={onChangeClientSelectHandler} defaultValue={item.client_id} token={props.token} />
           
            :  getCleint()
          }
          </td>
        </tr>
         
       
        <tr>
          <th className="tl">案件名 *</th>
          <td>
          {
            props.is_edit ? 
            <input autocomplete="off" type="text" className="col9" placeholder=""  name="name" value={item ? item.name : undefined}  onChange={changeHandler}/>
            : item ? item.name : null
          }
          </td>
        </tr>
      
        <tr>
          <th className="tl">種類 *</th>
          <td>
            {
              props.is_edit ? 
            <select name="category" value={item ? item.category : undefined}  onChange={changeHandler} >
              <option value="" selected="">選択してください</option>
              {
                config.get("PROJECT_CATEGORY").map(item => {
                  return(<option value={item.value}>{item.label}</option>)
                })
            }
            </select>
            : item ? Utile.getLabelByValue(config.get("PROJECT_CATEGORY"),Number(item.category)) : null
            }
          </td>
        </tr>
          { item && item.category &&  Number(item.category) === 7 ?
        <tr>
          <th className="tl">歩合単位 </th>
          <td>
            {
              props.is_edit ? 
            <select name="unit" value={item ? item.unit : undefined}  onChange={changeHandler} >
              <option value="" selected="">選択してください</option>
              {
                config.get("UNIT_TYPE").map(item => {
                  return(<option value={item.value}>{item.label}</option>)
                })
            }
            </select>
            : item ? Utile.getLabelByValue(config.get("UNIT_TYPE"),item.unit) : null
            }
          </td>
        </tr>
        : null }
        <tr>
          <th className="tl">締め日 *</th>
          <td>
          {props.is_edit ? 
            <select name="closing_date_day" value={item ? item.closing_date_day : undefined}  onChange={changeNumberHandler} >
              <option value="" selected="">選択してください</option>
                            {
                              config.get("PAYMENT_TYPE_DAY").map(_item => {
                                return(<option value={_item.value}>{_item.label}</option>)
                              })
                              }
                              </select>
                              :item ? Utile.getLabelByValue(config.get("PAYMENT_TYPE_DAY"),Number(item.closing_date_day)) : null

                            }
          </td>
        </tr>
        <tr>
          <th className="tl">月報ひな形 *</th>
          <td>
          {
              props.is_edit ? 
            <select name="report_type" value={item ? item.report_type : undefined}  onChange={changeNumberHandler} >
            <option value="" selected="">選択してください</option>
            {
               format_types
              }
            </select>
             : item ? format_label : "----"
            }
          </td>
        </tr>
        
        <tr>
          <th className="tl">距離設定 </th>
          <td>
          {
              props.is_edit ? <div><input autocomplete="off" type="number" className="col2" placeholder="10" step={1} name="overdistance_start" value={item ? item.overdistance_start : undefined}  onChange={changeNumberHandler} />km以上計算</div>
              
            : item ? <span>{item.overdistance_start}km以上計算</span> : null
          }
          </td>
        </tr>
        <tr>
          <th className="tl" >勤務時間設定 </th>
          <td>
          {
              props.is_edit ? 
            <select name="working_time_type" value={item ? item.working_time_type : undefined} onChange={changeHandler} >
            <option value="" selected="">選択してください</option>
            {
                config.get("PROJECT_WORKING_TIME_TYPE").map(item => {
                  return(<option value={item.value}>{item.label}</option>)
                })
              }
            </select>
              : item ? Utile.getLabelByValue(config.get("PROJECT_WORKING_TIME_TYPE"),Number(item.working_time_type)) : null
            }
          </td>
        </tr>
        <tr>
          <th className="tl">残業設定 </th>
          <td>
          {
              props.is_edit ? <div><input autocomplete="off" type="number" className="col2" placeholder="10" step={1} name="overtime_start" value={item ? item.overtime_start : undefined}  onChange={changeNumberHandler} />分以上<input autocomplete="off" type="number" className="col2" placeholder="10" step={1} name="overtime_unit" value={item ? item.overtime_unit : undefined}  onChange={changeNumberHandler} />分単位</div>
            
            : item ? <span>{item.overtime_start}分以上で{item.overtime_unit}単位</span> : null
          }
          </td>
        </tr>
        {item && Number(item.working_time_type) === 1 ? 
        <tr>
          <th className="tl" >時間設定開始時刻</th>
          <td>
          {
              props.is_edit ? 
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
              <TimePicker defaultValue={item ? new Date("1977-01-01 " +  item.overtime_start_at): undefined}ampm={false} onChange={(e) => {changeNameValueHandler("overtime_start_at", format(e, "HH:mm"))}}/>
            </LocalizationProvider>
             : item ? item.overtime_start_at : null
            }
          </td>
        </tr>
        : null}
        {item && Number(item.working_time_type) === 1 ? 
        <tr>
          <th className="tl">時間設定終了時刻</th>
          <td>
          {
              props.is_edit ? 
          
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
              <TimePicker defaultValue={item ? new Date("1977-01-01 " +  item.overtime_close_at): undefined} ampm={false} onChange={(e) => {changeNameValueHandler("overtime_close_at", format(e, "HH:mm"))}}/>
            </LocalizationProvider>
         
            : item ? item.overtime_close_at : null
          }
           </td>
        </tr>
        : null}
        {item && Number(item.working_time_type) === 2? 
        <tr>
          <th className="tl">拘束時間設定</th>
          <td>
          {
              props.is_edit ? 
              <span><input autocomplete="off" type="number" className="col5" max={24} min={0} placeholder="8" name="time_set" value={item ? item.time_set : undefined} onChange={changeHandler} /> 時間</span>
              : item ? item.time_set : null
            }
          </td>
        </tr>
        : null}
        <tr>
          <th className="tl">備考</th>
          <td>
          {
              props.is_edit ? <textarea type="text" className="col12" rows="5" name="note" value={item ? item.note : undefined} onChange={changeHandler} />
              : item ? item.note : null
          }
          </td>

        </tr>
      </tbody>
  </table>
    
  )
}

export default ProjectDetailItem

